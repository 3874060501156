import { uniq } from "lodash";

import { nullthrows } from "../assertions.js";
import { assertNever } from "../errors.js";
import { randomString } from "../uuid.js";

import {
  CalciteType,
  HqlAggregateTransform,
  HqlAggregation,
  HqlBinTransform,
  HqlBinaryOp,
  HqlBinaryOperator,
  HqlBinaryOperatorLiteral,
  HqlBinaryPredicateFunction,
  HqlBinaryPredicateFunctionLiteral,
  HqlBool,
  HqlColumn,
  HqlFloat,
  HqlFunc,
  HqlFunction,
  HqlInteger,
  HqlLimitTransform,
  HqlNode,
  HqlNull,
  HqlProjectTransform,
  HqlProjection,
  HqlQuery,
  HqlQueryColumn,
  HqlScalarFunction,
  HqlSortColumn,
  HqlSortOrder,
  HqlStr,
  HqlTransform,
  HqlTruncUnit,
  HqlUnaryOp,
  HqlUnaryOperator,
  HqlUnaryOperatorLiteral,
  HqlUnaryPredicateFunction,
  HqlUnaryPredicateFunctionLiteral,
} from "./types.js";

export function hqlCol(column: string): HqlColumn {
  return { type: "column", name: column };
}

export function hqlNull(): HqlNull {
  return { type: "null" };
}

export function hqlTrue(): HqlBool {
  return { type: "boolean", value: true };
}

export function hqlFalse(): HqlBool {
  return { type: "boolean", value: false };
}

export function hqlStr(value: string): HqlStr {
  return { type: "str", value };
}

export function hqlInt(value: number): HqlInteger {
  return { type: "integer", value };
}

export function hqlFloat(value: number): HqlFloat {
  return { type: "float", value };
}

export function hqlFunction(name: HqlFunction, args: HqlNode[]): HqlFunc {
  return { type: "function", name, args };
}

export function hqlBinaryOp(
  op: HqlBinaryOperator,
  left: HqlNode,
  right: HqlNode,
): HqlBinaryOp {
  return { type: "binaryOp", op, left, right };
}

export function hqlUnaryOp(op: HqlUnaryOperator, left: HqlNode): HqlUnaryOp {
  return { type: "unaryOp", op, left };
}

type ProjectTransform = Omit<HqlProjectTransform, "type">;
type AggregateTransform = Omit<HqlAggregateTransform, "type">;
type LimitTransform = Omit<HqlLimitTransform, "type">;
type FilterOp = HqlUnaryOp | HqlBinaryOp | HqlFunc;
type BinTransform = Omit<HqlBinTransform, "type">;

export class HqlSpecBuilder {
  private table: string;
  private columnTypes: HqlQueryColumn[];
  private transforms: HqlTransform[] = [];

  constructor(table: string, columnTypes: HqlQueryColumn[]) {
    this.table = table;
    this.columnTypes = columnTypes;
  }

  public project(projection: ProjectTransform): HqlSpecBuilder {
    // TODO: add type validation for projection
    this.transforms.push({
      type: "project",
      append: projection.append,
      projections: projection.projections,
    });
    return this;
  }

  public aggregate(aggregation: AggregateTransform): HqlSpecBuilder {
    // TODO: add type validation for aggregate
    this.transforms.push({
      type: "aggregate",
      groupby: aggregation.groupby,
      aggregations: aggregation.aggregations,
    });
    return this;
  }

  public filter(filter: FilterOp): HqlSpecBuilder {
    this.transforms.push({
      type: "filter",
      expr: filter,
    });
    return this;
  }

  public sort(columns: HqlSortColumn[]): HqlSpecBuilder {
    // TODO: add column validation for aggregate
    this.transforms.push({
      type: "sort",
      columns,
    });
    return this;
  }

  public limit(limit: LimitTransform): HqlSpecBuilder {
    this.transforms.push({
      type: "limit",
      limit: limit.limit,
      offset: limit.offset,
    });
    return this;
  }

  public bin(bin: BinTransform): HqlSpecBuilder {
    this.transforms.push({
      type: "bin",
      ...bin,
    });
    return this;
  }

  public addProjections(append: boolean): HqlProjectBuilder {
    return new HqlProjectBuilder(this, this.columnTypes, append);
  }

  public addAggregations(): HqlAggregateBuilder {
    return new HqlAggregateBuilder(this, this.columnTypes);
  }

  build(): HqlQuery {
    return {
      from: this.table,
      tables: {
        [this.table]: {
          columns: this.columnTypes,
        },
      },
      transforms: this.transforms,
    };
  }
}

abstract class HqlGenBuilder {
  protected parent: HqlSpecBuilder;
  protected sortColumns: HqlSortColumn[] = [];
  protected filters: FilterOp[] = [];
  protected columnTypes: HqlQueryColumn[];
  protected _limit: LimitTransform | null = null;

  protected currentColumn: string = "";
  private builderType: "project" | "aggregate";

  constructor(
    parent: HqlSpecBuilder,
    columnTypes: HqlQueryColumn[],
    type: "project" | "aggregate",
  ) {
    this.parent = parent;
    this.columnTypes = columnTypes;
    this.builderType = type;
  }

  public filter(
    args:
      | {
          op: HqlUnaryOperator;
        }
      | {
          op: HqlUnaryPredicateFunction;
          not?: boolean;
        }
      | {
          op: HqlBinaryPredicateFunction;
          arg: HqlNode;
          not?: boolean;
        }
      | {
          op: HqlBinaryOperator;
          arg: HqlNode;
          not?: boolean;
        },
  ): this {
    if (!this.currentColumn) {
      throw new Error(
        `Need to specify a ${this.builderType} column to filter before calling filter.`,
      );
    }

    const columnNode = hqlCol(this.currentColumn);

    let filter: FilterOp | undefined;

    if (HqlUnaryOperatorLiteral.guard(args.op)) {
      filter = hqlUnaryOp(args.op, columnNode);
    } else if (HqlUnaryPredicateFunctionLiteral.guard(args.op)) {
      filter = hqlFunction(args.op, [columnNode]);
    } else if (
      HqlBinaryPredicateFunctionLiteral.guard(args.op) &&
      "arg" in args
    ) {
      filter = hqlFunction(args.op, [columnNode, args.arg]);
    } else if (HqlBinaryOperatorLiteral.guard(args.op) && "arg" in args) {
      filter = hqlBinaryOp(args.op, columnNode, args.arg);
    }

    if (filter != null) {
      if ("not" in args && args.not) {
        filter = hqlUnaryOp("LOGICALNOT", nullthrows(filter));
      }

      this.filters.push(filter);
    }

    return this;
  }

  public sort(order: HqlSortOrder): this {
    if (!this.currentColumn) {
      throw new Error(
        `Need to specify a ${this.builderType} column to sort before calling sort.`,
      );
    }

    this.sortColumns.push({
      column: this.currentColumn,
      order,
    });
    return this;
  }

  public limit(limit: LimitTransform): this {
    this._limit = limit;
    return this;
  }

  protected getCombinedFilters(): FilterOp | undefined {
    // TODO: support ORs
    if (this.filters.length > 1) {
      let filter = hqlBinaryOp(
        "LOGICALAND",
        nullthrows(this.filters[0]),
        nullthrows(this.filters[1]),
      );

      for (let i = 2; i < this.filters.length; i++) {
        filter = hqlBinaryOp("LOGICALAND", filter, nullthrows(this.filters[i]));
      }

      return filter;
    } else if (this.filters.length > 0) {
      return this.filters[0];
    }
  }

  protected genUniqueColumnName(): string {
    return `_hex_${randomString(4)}`;
  }
}

class HqlProjectBuilder extends HqlGenBuilder {
  private append: boolean;
  private projections: HqlProjection[] = [];

  constructor(
    parent: HqlSpecBuilder,
    columnTypes: HqlQueryColumn[],
    append: boolean,
  ) {
    super(parent, columnTypes, "project");
    this.append = append;
  }

  public projection(projection: HqlProjection): HqlProjectBuilder {
    const as = projection.as ?? this.genUniqueColumnName();
    projection.as = as;
    this.currentColumn = as;
    this.projections.push(projection);
    return this;
  }

  public column({
    as,
    column,
  }: {
    column: string;
    as?: string;
  }): HqlProjectBuilder {
    const asColumn = as ?? column;
    this.currentColumn = asColumn;
    this.projection({ expr: hqlCol(column), as: asColumn });
    return this;
  }

  public datecast(args: { column: string; as?: string }): HqlProjectBuilder {
    const columnType = this.columnTypes.find(
      (c) => c.name === args.column,
    )?.type;
    const as = args.as ?? this.genUniqueColumnName();
    if (columnType == null || columnType === CalciteType.VARCHAR) {
      this.projection({
        expr: hqlFunction("ToDatetime", [hqlCol(args.column)]),
        as,
      });
    } else if (
      columnType === CalciteType.BIGINT ||
      columnType === CalciteType.FLOAT ||
      columnType === CalciteType.DOUBLE ||
      columnType === CalciteType.INTEGER ||
      columnType === CalciteType.DECIMAL
    ) {
      this.projection({
        expr: hqlFunction("EpochMsToDatetime", [hqlCol(args.column)]),
        as,
      });
    } else if (columnType === CalciteType.BOOLEAN) {
      // kind of silly, but internally consistent
      this.projection({
        expr: hqlFunction("EpochMsToDatetime", [
          hqlFunction("If", [hqlCol(args.column), hqlInt(1), hqlInt(0)]),
        ]),
        as,
      });
    } else if (
      columnType === CalciteType.TIMESTAMP ||
      columnType === CalciteType.TIMESTAMPTZ ||
      columnType === CalciteType.DATE
    ) {
      this.projection({
        expr: hqlCol(args.column),
        as,
      });
    } else {
      assertNever(columnType, columnType);
    }

    this.currentColumn = as;
    return this;
  }

  public numbercast(args: { column: string; as?: string }): HqlProjectBuilder {
    const columnType = this.columnTypes.find(
      (c) => c.name === args.column,
    )?.type;
    const as = args.as ?? this.genUniqueColumnName();
    if (columnType == null || columnType === CalciteType.VARCHAR) {
      this.projection({
        expr: hqlFunction("ToNumber", [hqlCol(args.column)]),
        as,
      });
    } else if (
      columnType === CalciteType.DATE ||
      columnType === CalciteType.TIMESTAMP ||
      columnType === CalciteType.TIMESTAMPTZ
    ) {
      this.projection({
        expr: hqlFunction("DatetimeToEpochMs", [hqlCol(args.column)]),
        as,
      });
    } else if (columnType === CalciteType.BOOLEAN) {
      this.projection({
        expr: hqlFunction("If", [hqlCol(args.column), hqlInt(1), hqlInt(0)]),
        as,
      });
    } else if (
      columnType === CalciteType.BIGINT ||
      columnType === CalciteType.FLOAT ||
      columnType === CalciteType.DOUBLE ||
      columnType === CalciteType.INTEGER ||
      columnType === CalciteType.DECIMAL
    ) {
      this.projection({
        expr: hqlCol(args.column),
        as,
      });
    } else {
      assertNever(columnType, columnType);
    }

    this.currentColumn = as;
    return this;
  }

  public datetrunc(args: {
    column: string;
    unit: HqlTruncUnit;
    as?: string;
  }): HqlProjectBuilder {
    // TODO: handle column type validation
    const funcMap = {
      year: HqlScalarFunction.TruncYear,
      quarter: HqlScalarFunction.TruncQuarter,
      month: HqlScalarFunction.TruncMonth,
      week: HqlScalarFunction.TruncWeek,
      day: HqlScalarFunction.TruncDay,
      hour: HqlScalarFunction.TruncHour,
      minute: HqlScalarFunction.TruncMinute,
      second: HqlScalarFunction.TruncSecond,
      dayofweek: HqlScalarFunction.DayOfWeek,
    };

    const as = args.as ?? this.genUniqueColumnName();
    this.projection({
      expr: hqlFunction(funcMap[args.unit], [hqlCol(args.column)]),
      as,
    });
    this.currentColumn = as;

    return this;
  }

  public buildProject(): HqlSpecBuilder {
    const builder = this.parent;
    if (this.projections.length > 0) {
      builder.project({
        append: this.append,
        projections: this.projections,
      });
    }

    const filter = this.getCombinedFilters();
    if (filter != null) {
      builder.filter(filter);
    }

    if (this.sortColumns.length > 0) {
      builder.sort(this.sortColumns);
    }

    if (this._limit) {
      builder.limit(this._limit);
    }

    return builder;
  }
}

class HqlAggregateBuilder extends HqlGenBuilder {
  private aggregations: HqlAggregation[] = [];
  private groupbys: string[] = [];

  constructor(parent: HqlSpecBuilder, columnTypes: HqlQueryColumn[]) {
    super(parent, columnTypes, "aggregate");
  }

  public aggregation(aggregation: HqlAggregation): HqlAggregateBuilder {
    const as = aggregation.as ?? this.genUniqueColumnName();
    aggregation.as = as;
    this.currentColumn = as;
    this.aggregations.push(aggregation);
    return this;
  }

  public groupby(groupby: string): HqlAggregateBuilder {
    this.currentColumn = groupby;
    this.groupbys.push(groupby);
    return this;
  }

  public buildAggregate(): HqlSpecBuilder {
    const builder = this.parent;
    if (this.aggregations.length > 0) {
      builder.aggregate({
        groupby: uniq(this.groupbys),
        aggregations: this.aggregations,
      });
    }

    const filter = this.getCombinedFilters();
    if (filter != null) {
      builder.filter(filter);
    }

    if (this.sortColumns.length > 0) {
      builder.sort(this.sortColumns);
    }

    if (this._limit) {
      builder.limit(this._limit);
    }

    return builder;
  }
}
