import { ExtractAtomicOperationFromDefinition } from "../../../atomic-operations";
import {
  ChartColorMappings,
  ChartSelection,
  ChartSpec,
} from "../../../chart/types";
import { DisplayTableConfigPayload } from "../../../display-table";
import { ChartDisplayType } from "../../../enums.js";
import { ChartCellId } from "../../../idTypeBrands";
import { makeUpdateCellContentsOperation } from "../makeUpdateCellContentsOperation";

export interface ChartCellUpdatableFields {
  chartSpec: ChartSpec | null;
  chartSelection: ChartSelection | null;
  height: number | null;
  resultVariable: string;
  outputResult: boolean | null;
  chartDisplayTableConfig: DisplayTableConfigPayload | null;
  colorMappings: ChartColorMappings | null;
  displayType: ChartDisplayType | null;
}

const _UpdateChartCellSafeFields: {
  [key in keyof ChartCellUpdatableFields]: "";
} = {
  chartSpec: "",
  chartSelection: "",
  height: "",
  resultVariable: "",
  outputResult: "",
  chartDisplayTableConfig: "",
  colorMappings: "",
  displayType: "",
};
export const UpdateChartCellSafeFields = Object.keys(
  _UpdateChartCellSafeFields,
);

export const UPDATE_CHART_CELL = makeUpdateCellContentsOperation<
  ChartCellUpdatableFields,
  ChartCellId,
  { preventRun?: boolean }
>()({
  type: "UPDATE_CHART_CELL",
  entityName: "ChartCell",
  fieldName: "chartCellId",
  // colorMapping updates are not user-triggered but instead auto-triggered
  // when the chart renders, so we don't want to show it in history.
  // We could get rid of this issue by updating the colorMappings in the server
  // when the chart spec is updated, but because color mappings require dataframe
  // value information to compute (the data gets passed from the kernel to the
  // chart translator when the cell is executed), we don't have that information
  // in the server at the time we're updating the chart spec.
  excludeFromHistory: ({ payload }) => payload.key === "colorMappings",
});

export type UPDATE_CHART_CELL = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_CHART_CELL
>;
