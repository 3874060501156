import {
  Boolean,
  Dictionary,
  Literal,
  Null,
  Optional,
  Number as RNumber,
  Record as RRecord,
  String as RString,
  Static,
  Union,
} from "runtypes";

import { notEmpty } from "./notEmpty.js";
import { StripeMeterId, StripePriceId } from "./stripeIdTypeBrands.js";
import { KernelImage } from "./typeBrands";
import { typedObjectEntries, typedObjectKeys } from "./utils/typedObjects";

export const SIGNED_EMBEDDED_KERNEL_IDLE_TIMEOUT_MS = 5 * 60 * 1_000; // 5 minutes
export const EXPLORE_IDLE_TIMEOUT_MS = 30 * 60 * 1000; // 30 minutes

export const DEFAULT_KERNEL_SIZE = "medium";

export const StandardKernelOptionsUnionLiteral = Union(
  Literal("xsmall"),
  Literal("small"),
  Literal("medium"),
  Literal("large"),
  Literal("xlarge"),
);

const KernelOptionsUnionLiteral = Union(
  StandardKernelOptionsUnionLiteral,
  Literal("2xlarge"),
  Literal("4xlarge"),
  Literal("v100"),
  Literal("v100x8"),
);

export const KernelSize = KernelOptionsUnionLiteral;
export type KernelSize = Static<typeof KernelOptionsUnionLiteral>;

export const KernelResourceConfig = RRecord({
  cpu: RString,
  memory: RString,
  "nvidia.com/gpu": Optional(RString),
});
export type KernelResourceConfig = Static<typeof KernelResourceConfig>;

const KernelSizeConfig = RRecord({
  humanName: RString,
  computePool: Optional(RString.nullable()),
  resources: RRecord({
    limits: KernelResourceConfig,
    requests: KernelResourceConfig,
  }),
  nodeGroup: Optional(RString.nullable()),
});

const KernelImageReplicas = Dictionary(Null, KernelSize);
type KernelImageReplicas = Static<typeof KernelImageReplicas>;

const KernelImageConfig = RRecord({
  name: RString,
  priority: Optional(RNumber),
  imageUrl: Optional(RString.nullable()),
  replicas: KernelImageReplicas,
});
type KernelImageConfig = Static<typeof KernelImageConfig>;

const KernelImages = Dictionary(KernelImageConfig, KernelImage);
type KernelImages = Static<typeof KernelImages>;

export const KernelConfig = RRecord({
  tag: RString,
  image: RString,
  defaultBaseImage: KernelImage,
  baseImages: KernelImages,
});
export type KernelConfig = Static<typeof KernelConfig>;

export const KernelSizes = Dictionary(KernelSizeConfig, KernelSize);
export type KernelSizes = Static<typeof KernelSizes>;

export const KernelManagerConfig = RRecord({
  backendHostname: RString,
  sslEnabled: Boolean,
  publicKernelSize: KernelSize,
  kernelSizes: KernelSizes,
  pythonKernel: KernelConfig,
  rKernel: KernelConfig,
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
}).withConstraint(({ kernelSizes, pythonKernel, rKernel }) => {
  // TypeORM wants you to return a string in a case of failure
  return (
    validateKernelConfig(rKernel, kernelSizes) ??
    validateKernelConfig(pythonKernel, kernelSizes) ??
    true
  );
});

export type KernelManagerConfig = Static<typeof KernelManagerConfig>;

function validateKernelConfig(
  kernelConfig: KernelConfig,
  kernelSizes: KernelSizes,
): string | undefined {
  if (!(kernelConfig.defaultBaseImage in kernelConfig.baseImages)) {
    return `Default base image ${kernelConfig.defaultBaseImage} not found in base images`;
  }
  const failures = typedObjectEntries(kernelConfig.baseImages)
    .map(([baseImage, baseImageConfig]) => {
      const imageReplicas = typedObjectKeys(baseImageConfig.replicas);
      if (imageReplicas.length !== Object.keys(kernelSizes).length) {
        return `Base image ${baseImage} does not specify replicas for all kernel sizes`;
      }
      imageReplicas.forEach((kernelSize) => {
        if (!(kernelSize in kernelSizes)) {
          return `Base image ${baseImage} specifies replicas for invalid kernel size ${kernelSize}`;
        }
      });
    })
    .filter(notEmpty);

  if (failures.length > 1) {
    return failures.join(".\n");
  }
}

interface BillingConfig {
  priceId: StripePriceId | null;
  eventName: string;
  meterId: StripeMeterId | null;
}
export type ComputeProfileBillingConfig = Record<KernelSize, BillingConfig>;
